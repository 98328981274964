// extracted by mini-css-extract-plugin
export var animationMarquee = "k_c";
export var arrow = "k_K";
export var arrow_close = "k_J";
export var body = "k_M";
export var container = "k_b";
export var inner = "k_h";
export var item = "k_g";
export var label = "k_j";
export var list = "k_l";
export var listWrapper = "k_L";
export var title = "k_k";
import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import Seo from "@components/SEO"
import { Section, Title, Paragraph, Column } from "@components/blocks/page/Section"
import { Table } from "@components/blocks/page/Table"
import { Tags } from "@components/blocks/page/Tags"

import * as css from "@css/components/blocks/page/Content.module.styl"

export default function Company()
{
	return(
		<>
		<Seo/>
        <div className={css.container}>
            <div className={css.inner}>
                <Section>
                    <Title title1='COMPANY' title2='会社概要' />
                    <Table
                        data={[
                                [
                                    `会社名`,
                                    `株式会社BE-STRONG`
                                ],[
                                    `所在地`,
                                    `〒039-1121 青森県八戸市卸センター2-6-25`
                                ],[
                                    `設立`,
                                    `2019年9月`
                                ],[
                                    `代表者`,
                                    `三浦　大助`
                                ],[
                                    `資本金`,
                                    `10,000,000円`
                                ], [
                                    `年商`,
                                    ` 年商 1億円／2020年時点`
                                ], [
                                    `決算期`,
                                    `8月20日`
                                ], [
                                    `事業内容`,
                                    `輸出入`
                                ], [
                                    `販売実績`,
                                    `不織布マスク、防護服、タイヤチェーンバンド、他`
                                ], [
                                    `主な取引国`,
                                    `中華人民共和国、タイ`
                                ],
                        ]}
                    />
                </Section>
            </div>
        </div>
</>
	)
}
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Link } from "@components/Link"
import { Svg } from "@components/Svg"
import * as css from "@css/components/blocks/page/Content.module.styl"

function Title({title1,title2})
{
    const q = useStaticQuery(graphql`
        query {
            information : file(relativePath: { eq: "IndexInformation__lbl__title.svg" }) { publicURL }
            privacy : file(relativePath: { eq: "IndexPrivacy__lbl__title.svg" }) { publicURL }
            company : file(relativePath: { eq: "IndexCompany__lbl__title.svg" }) { publicURL }
        }
    `)

    let titleDom = () => {
        let dom
        if( title1 === "INFORMATION"){
            dom = <span className={css.information}>
                <img src={q.information.publicURL} alt="INFORMATION"/>
            </span>
        } else 
        if( title1 === "PRIVACY POLICY"){
            dom = <span className={css.privacy}>
                <img src={q.privacy.publicURL} alt="PRIVACY POLICY"/>
            </span>
        } else
        if( title1 === "COMPANY"){
            dom = <span className={css.company}>
                <img src={q.company.publicURL} alt="COMPANY"/>
            </span>
        } else {
            dom = <span className={css.l1}>{title1}</span>
        }
        return dom
    }
    return(
        <h3 className={css.title}>
            { titleDom() }
            { title2 && <span className={css.l2}>{title2}</span> }
        </h3>
    )
}

function SmallTitle({ title1, title2 }) {
    return (
        <h4 className={css.title_small}>
            <span className={css.l1}>{title1}</span>
            { title2 && <span className={css.l2}>{title2}</span> }
        </h4>
    )
}


function Section({ children, ...props }) {
    return (
        <section className={css.section} {...props}>
            {children}
        </section>
    )
}
function Paragraph({ children }) {
    return (
        <div className={css.paragraph}>
            {children}
        </div>
    )
}
function Column({children}){
    return(
        <div className={css.column2}>
            {children}
        </div>
    )
}

export { Section, Title, SmallTitle, Paragraph, Column }
// extracted by mini-css-extract-plugin
export var animationMarquee = "g_c";
export var button = "g_p";
export var buttons = "g_f";
export var column2 = "g_B";
export var company = "g_s";
export var container = "g_b";
export var information = "g_r";
export var inner = "g_h";
export var l1 = "g_v";
export var l2 = "g_w";
export var num = "g_z";
export var paragraph = "g_y";
export var privacy = "g_t";
export var section = "g_q";
export var title = "g_k";
export var title_small = "g_x";